<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="contact-us-banner solution-header-container">
      <div class="solution-header-text" style="margin-top: -80px">
        <h1 data-caption-delay="0" class="wow fadeInLeft">联系我们</h1>
        <p data-caption-delay="100" class="wow fadeInRight">为您提供定制化咨询服务</p>
      </div>
    </div>
    <div class="all">
      <div class="company company-contact wow fadeInRight">
        <div class="container">
          <el-row>
            <el-col :span="24">
              <div class="headquarters">沈阳东信创智科技有限公司（总部）</div>
            </el-col>
          </el-row>
          <el-row class="company-shenyang">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="information">
                <p class="line">地址：辽宁省沈阳市浑南区创新路175-2号B2座301室 <br/></p>
                <p class="line">邮编：110167<br/></p>
                <p class="line">电话：13664132238<br/></p>
                <p class="line">传真：024-8370 8679<br/></p>
                <p class="line">邮箱：market@dotrustech.com<br/></p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <!-- <img class="address" src="../../assets/images/company-address-shenyang.png" alt=""> -->
              <baidu-map class="address" :center="center" :zoom="zoom">
                <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :offset="scaleOffset"></bm-scale>
                <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" :offset="navigationOffset"></bm-navigation>
                <bm-marker :position="center" :dragging="false">
                  <bm-label
                      content="沈阳东信创智科技有限公司（总部）"
                      :labelStyle="{color: '#ED2D2D', fontSize : '13px',WebkitTextStroke: '0.1px #ffffff',TextStroke: '0.1px #ffffff',fontWeight:'800', border:'none', padding:'0px 0 1px 5px', background:'none'}"
                      :offset="{width: -85, height: 25}"/>
                </bm-marker>
              </baidu-map>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <p class="subtitle">北京 · 分公司</p>
            </el-col>
            <el-col :span="12">
              <p class="subtitle-2">武汉 · 分公司</p>
            </el-col>
          </el-row>
          <el-row class="branch-company">
            <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4" class="picture-body">
              <img class="company-picture" src="../../assets/images/branch-beijing.jpg" alt="">
            </el-col>
            <el-col :xs="12" :sm="9" :md="9" :lg="9" :xl="9">
              <div class="branch-address">
                <p class="">地址：北京市昌平区<br/></p>
                <p class="address-line">回龙观西大街16号院<br/></p>
                <p class="address-line">龙冠商务中心银座4层411</p>
              </div>
              <p class="mailbox">邮编：100085</p>
            </el-col>
            <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4" class="picture-body">
              <img class="company-picture" src="../../assets/images/branch-wuhan.jpg" alt="">
            </el-col>
            <el-col :xs="12" :sm="7" :md="7" :lg="7" :xl="7">
              <div class="branch-address">
                <p class="">地址：湖北省武汉市经开区<br/></p>
                <p class="address-line">南太子湖创新谷二期<br/></p>
                <p class="address-line">5B号楼301室</p>
              </div>
              <p class="mailbox">邮编：430056</p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <p class="subtitle">上海 · 子公司</p>
            </el-col>
            <el-col :span="12">
              <p class="subtitle-2">重庆 · 子公司</p>
            </el-col>
          </el-row>
          <el-row class="branch-company">
            <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4" class="picture-body">
              <img class="company-picture" src="../../assets/images/branch-shanghai.jpg" alt="">
            </el-col>
            <el-col :xs="12" :sm="9" :md="9" :lg="9" :xl="9">
              <div class="branch-address">
                <p class="">地址：上海市嘉定区<br/></p>
                <p class="address-line">于田南路36号602室<br/></p>
              </div>
              <p class="mailbox">邮编：200335</p>
            </el-col>
            <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4" class="picture-body">
              <img class="company-picture" src="../../assets/images/branch-chongqing.jpg" alt="">
            </el-col>
            <el-col :xs="12" :sm="7" :md="7" :lg="7" :xl="7">
              <div class="branch-address">
                <p class="">地址：重庆市沙坪坝区<br/></p>
                <p class="address-line">景阳路37号<br/></p>
                <p class="address-line">B3幢11楼1105室</p>
              </div>
              <p class="mailbox">邮编：401122</p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <p class="subtitle">天津 · 办事处</p>
            </el-col>
            <el-col :span="12">
              <p class="subtitle-2">广州 · 办事处</p>
            </el-col>
          </el-row>
          <el-row class="branch-company">
            <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4" class="picture-body">
              <img class="company-picture" src="../../assets/images/branch-tianjin.jpg" alt="">
            </el-col>
            <el-col :xs="12" :sm="9" :md="9" :lg="9" :xl="9">
              <div class="branch-address">
                <p class="">地址：天津市西青区<br/></p>
                <p class="address-line">中北镇新城市中心写字楼<br/></p>
                <p class="address-line">C座9楼903号</p>
              </div>
              <p class="mailbox">邮编：300451</p>
            </el-col>
            <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4" class="picture-body">
              <img class="company-picture" src="../../assets/images/branch-guangzhou.jpeg" alt="">
            </el-col>
            <el-col :xs="12" :sm="7" :md="7" :lg="7" :xl="7">
              <div class="branch-address">
                <p class="">地址：广州市番禺区<br/></p>
                <p class="address-line">大龙街番禺大道北1480号<br/></p>
                <p class="address-line">磐基大厦11楼 1114室</p>
              </div>
              <p class="mailbox">邮编：510000</p>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue";
import mainFooter from "@/components/MainFooter.vue";
import "@/style/contactUs.less"
import "@/style/joinUs.less"
import bzInfo from "@/components/BzInfo.vue";

export default {
  name: "contactUs",
  components: {
    bzInfo,
    mainNav,
    mainFooter
  },
  data() {
    return {
      activeIndex: '7',
      center: {lng: 123.446254, lat: 41.662649},
      zoom: 15,
      scaleOffset: {
        width: 20,
        height: 20
      },
      navigationOffset: {
        width: 5,
        height: 40
      },
    }
  },
  mounted() {
    document.title = "联系我们 - 东信创智"
    new this.$wow.WOW().init()
  }
}
</script>

<style scoped>

</style>
